import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/homePage",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/homePage",
        name: "Home Page",
        component: () => import("@/views/pages/MenuPages/HomePage.vue"),
      },
      {
        path: "/serviceManagement/",
        name: "Service Management",
        component: () =>
          import("@/views/pages/RouterViews/ServiceManagementRouterView.vue"),
        children: [
          {
            path: "",
            name: "Service Management",
            component: () =>
              import("@/views/pages/MenuPages/ServiceManagement.vue"),
          },
          {
            path: "encounterNew",
            component: () =>
              import("@/views/pages/Encounter/EncounterPage.vue"),
            props: true,
          },
          {
            path: "encounter/:encounterId",
            component: () =>
              import("@/views/pages/Encounter/EncounterPage.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/claimManagement",
        name: "Claim Management",
        component: () =>
          import("@/views/pages/RouterViews/ClaimManagementRouterView.vue"),
        children: [
          {
            path: "",
            name: "Claim Management",
            component: () =>
              import("@/views/pages/MenuPages/ClaimManagement.vue"),
          },
          {
            path: "claim/:claimId",
            component: () => import("@/views/pages/Claim/ClaimPageV2.vue"),
            props: true,
            children: [
              {
                path: "effectivePayers",
                component: () => import("@/modules/claims/EffectivePayers.vue"),
                props: true,
              },
              {
                path: "services",
                component: () => import("@/modules/claims/Services.vue"),
                props: true,
              },
              {
                path: "professionalServices",
                component: () =>
                  import("@/modules/claims/ProfessionalServices.vue"),
                props: true,
              },
              {
                path: "claimSummary",
                component: () => import("@/views/pages/Claim/ClaimSummary.vue"),
                props: true,
              },
              {
                path: "waystarForm",
                component: () =>
                  import("@/modules/waystarForm/WaystarFormComponent.vue"),
                props: true,
              },
              {
                path: "submissionHistory",
                component: () =>
                  import("@/modules/claims/SubmissionHistory.vue"),
                props: true,
              },
              {
                path: "notes",
                component: () =>
                  import("@/modules/claims/ClaimNotesComponent.vue"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "/payments/",
        name: "Payment Management",
        component: () =>
          import("@/views/pages/RouterViews/PaymentsRouterView.vue"),
        children: [
          {
            path: "",
            redirect: "/Payments/PatientTransactionsList",
            component: () => import("@/views/pages/MenuPages/PaymentsPage.vue"),
            children: [
              {
                path: "CardTransactions",
                name: "CardTransactions",
                component: () =>
                  import("@/views/pages/Payments/CreditCardPayment.vue"),
              },
              {
                path: "PatientTransactionsList",
                name: "PatientTransactionsList",
                component: () =>
                  import("@/views/pages/Payments/PatientTransactionsList.vue"),
              },
              {
                path: "InsuranceTransactionsList",
                name: "InsuranceTransactionsList",
                component: () =>
                  import(
                    "@/views/pages/Payments/InsuranceTransactionsList.vue"
                  ),
              },
              {
                path: "PaymentPlanList",
                component: () =>
                  import("@/views/pages/PaymentPlan/PaymentPlanList.vue"),
                props: true,
              },
              {
                path: "InsuranceRemitsList",
                component: () =>
                  import(
                    "@/views/pages/InsuranceRemits/InsuranceRemitsList.vue"
                  ),
                props: true,
              },
              {
                path: "UnappliedCredits",
                name: "UnappliedCredits",
                component: () =>
                  import("@/views/pages/Payments/UnappliedCredits.vue"),
              },
            ],
          },
          {
            path: "patientPayment",
            component: () =>
              import("@/views/pages/Payments/PatientPayment.vue"),
            props: true,
          },
          {
            path: "/patientPayment/:paymentId",
            component: () =>
              import("@/views/pages/Payments/PatientPayment.vue"),
            props: true,
          },
          {
            path: "patientRefund",
            component: () => import("@/views/pages/Payments/PatientRefund.vue"),
            props: true,
          },
          {
            path: "patientRefund/:paymentId",
            component: () => import("@/views/pages/Payments/PatientRefund.vue"),
            props: true,
          },
          {
            path: "insurancePayment",
            component: () =>
              import("@/views/pages/Payments/InsurancePayment.vue"),
            props: true,
          },
          {
            path: "/insurancePayment/:paymentId",
            component: () =>
              import("@/views/pages/Payments/InsurancePayment.vue"),
            props: true,
          },
          {
            path: "insurancePaymentRefund",
            component: () =>
              import("@/views/pages/Payments/InsurancePaymentRefund.vue"),
            props: true,
          },
          {
            path: "/insurancePaymentRefund/:paymentId",
            component: () =>
              import("@/views/pages/Payments/InsurancePaymentRefund.vue"),
            props: true,
          },
          {
            path: "paymentPlan",
            component: () =>
              import("@/views/pages/PaymentPlan/PaymentPlan.vue"),
            props: true,
          },
          {
            path: "/paymentPlan/:paymentPlanId",
            component: () =>
              import("@/views/pages/PaymentPlan/PaymentPlan.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/patientAccounts",
        name: "Patient Accounts",
        component: () =>
          import("@/views/pages/RouterViews/PatientAccountsRouterView.vue"),
        children: [
          {
            path: "",
            name: "Patient Accounts",
            component: () =>
              import("@/views/pages/MenuPages/PatientAccounts.vue"),
          },
          {
            path: "patient/:patientId/patientInsurance/:patientPayerId?",
            component: () =>
              import("@/modules/patient/PatientInsuranceComponentV2.vue"),
            props: true,
          },
          {
            path: "patient/:patientId/guarantor/:guarantorId?",
            component: () => import("@/modules/guarantors/Guarantor.vue"),
            props: true,
          },
          {
            path: "patient/:patientId/superbill",
            component: () => import("@/views/pages/MenuPages/Superbill.vue"),
            props: true,
          },
          {
            path: "patient/:patientId",
            component: () => import("@/views/pages/Patient/PatientPage.vue"),
            props: true,
            children: [
              {
                path: "patientDemographics",
                component: () =>
                  import(
                    "@/modules/patient/PatientDemographicsComponentV2.vue"
                  ),
                props: true,
              },
              {
                path: "patientInsurances",
                component: () =>
                  import("@/modules/patient/PatientInsuranceListComponent.vue"),
                props: true,
              },
              {
                path: "patientTransactions",
                component: () => import("@/modules/patient/Transactions.vue"),
                props: true,
              },
              {
                path: "paymentPlans",
                component: () =>
                  import("@/modules/patient/PatientPaymentPlans.vue"),
                props: true,
              },
              {
                path: "serviceHistory",
                component: () =>
                  import("@/modules/patient/ServiceHistoryV2.vue"),
                props: true,
              },
              {
                path: "patientStatements",
                component: () => import("@/modules/patient/Statements.vue"),
                props: true,
              },
              {
                path: "unappliedCredit",
                component: () =>
                  import("@/modules/patient/UnappliedCredit.vue"),
                props: true,
              },
              {
                path: "guarantorList",
                component: () =>
                  import("@/modules/guarantors/GuarantorsList.vue"),
                props: true,
              },
              {
                path: "notes",
                component: () => import("@/modules/patient/PatientNotes.vue"),
                props: true,
              },
              {
                path: "nexio",
                component: () => import("@/modules/patient/PatientNexio.vue"),
                props: true,
              },
            ],
          },
        ],
      },
      {
        path: "/statements",
        name: "Statements",
        component: () => import("@/views/pages/MenuPages/Statements.vue"),
      },
      {
        path: "/settings",
        name: "Settings",
        component: () =>
          import("@/views/pages/RouterViews/SettingsRouterView.vue"),
        children: [
          {
            path: "",
            name: "Settings",
            redirect: "/settings/providersPage",
            component: () => import("@/views/pages/Settings/SettingsPage.vue"),
            children: [
              {
                path: "providersPage",
                name: "providersPage",
                component: () =>
                  import("@/views/pages/Settings/ProvidersPage.vue"),
              },
              {
                path: "facilitiesPage",
                name: "facilitiesPage",
                component: () =>
                  import("@/views/pages/Settings/FacilitiesPage.vue"),
              },
              {
                path: "referringProvidersPage",
                name: "referringProvidersPage",
                component: () =>
                  import("@/views/pages/Settings/ReferringProvidersPage.vue"),
              },
              {
                path: "dropdownsPage",
                name: "dropdownsPage",
                component: () =>
                  import("@/views/pages/Settings/DropdownsPage.vue"),
              },
              {
                path: "codeMasterPage",
                component: () =>
                  import("@/views/pages/Settings/CodeMarterPage.vue"),
              },
              {
                name: "insuranceCompanyPage",
                path: "insuranceCompanyPage",
                component: () =>
                  import("@/views/pages/Settings/InsuranceCompanyPage.vue"),
                props: true,
              },
              {
                path: "statementSettings",
                component: () =>
                  import("@/views/pages/Settings/StatementSettings.vue"),
              },
              {
                path: "usersPage",
                component: () => import("@/views/pages/Settings/UsersPage.vue"),
              },
              {
                path: "serviceMessages",
                component: () =>
                  import("@/views/pages/Settings/ServiceMessages.vue"),
              },
            ],
          },
          {
            path: "providerPage/:providerId/",
            name: "providerPage",
            component: () => import("@/views/pages/Settings/ProviderPage.vue"),
            props: true,
          },
          {
            path: "facilityPage",
            name: "facilityPage",
            component: () => import("@/views/pages/Settings/FacilityPage.vue"),
            props: true,
          },
          {
            path: "referringProviderPage",
            name: "referringProviderPage",
            component: () =>
              import("@/views/pages/Settings/ReferringProviderPage.vue"),
            props: true,
          },
          {
            name: "insuranceCompanyAddNewPage",
            path: "insuranceCompanyAddNewPage",
            component: () =>
              import("@/views/pages/Settings/InsuranceCompanyAddPage.vue"),
            props: true,
          },
          {
            name: "insuranceCompanyAddPage",
            path: "insuranceCompanyAddPage/:insuranceCompanyId",
            component: () =>
              import("@/views/pages/Settings/InsuranceCompanyAddPage.vue"),
            props: true,
          },
          {
            path: "facilityPageAfterEncounter",
            name: "facilityPageAfterEncounter",
            component: () => import("@/views/pages/Settings/FacilityPage.vue"),
            props: true,
          },
          {
            path: "providerPageAfterEncounter",
            name: "providerPageAfterEncounter",
            component: () => import("@/views/pages/Settings/ProviderPage.vue"),
            props: true,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/open-id-login",
        component: () =>
          import("@/views/crafted/authentication/open-id/SingIn.vue"),
        props: true,
      },
      {
        path: "/callback",
        component: () =>
          import("@/views/crafted/authentication/open-id/AuthCallback.vue"),
        props: true,
      },
      {
        path: "/logout",
        component: () =>
          import("@/views/crafted/authentication/open-id/LogOut.vue"),
        props: true,
      },
      {
        path: "/authcallback",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/CallBack.vue"),
        props: true,
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/devtools/devconfig",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/devtools/devconfig",
        name: "devconfig",
        component: () => import("@/views/pages/DevTools/DevConfig.vue"),
      },
    ],
  },
  {
    path: "/devtools/devclaims",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/devtools/devclaims",
        name: "devclaims",
        component: () => import("@/views/pages/DevTools/DevClaims.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
});

export default router;
